// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated, getCurrentUser } from '../services/authService';

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }
  
  // Check if email is verified
  const user = getCurrentUser();
  if (user && !user.isEmailVerified) {
    // Redirect to login page with a query parameter to show verification message
    return <Navigate to="/login?verification=required" />;
  }
  
  return children;
};

export default ProtectedRoute;