import React, { useState, useEffect } from 'react';
import PDFPreview from './PDFPreview';
import { getUserProfile, getPDFSettings } from '../services/authService';

const OrderPDFPreview = ({ order, customer }) => {
  const [loading, setLoading] = useState(true);
  const [pdfSettings, setPdfSettings] = useState({
    primaryColor: '#1E40AF',
    secondaryColor: '#F3F4F6',
    template: 'classic',
    showBankAccount: true,
    footerNotes: ''
  });
  
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        
        // Load PDF settings and user profile in parallel
        const [pdfData, userData] = await Promise.all([
          getPDFSettings(),
          getUserProfile()
        ]);
        
        // Set PDF settings
        if (pdfData) {
          setPdfSettings({
            primaryColor: pdfData.primaryColor || '#1E40AF',
            secondaryColor: pdfData.secondaryColor || '#F3F4F6',
            template: pdfData.template || 'classic',
            showBankAccount: pdfData.showBankAccount !== undefined ? pdfData.showBankAccount : true,
            footerNotes: pdfData.footerNotes || ''
          });
        }
        
        // Set company info without default values
        if (userData) {
          const cleanCompanyInfo = {
            company: userData.company || '',
            name: userData.name || '',
            address: userData.address || '',
            postalCode: userData.postalCode || '',
            city: userData.city || '',
            email: userData.email || '',
            // Only include fields that have actual values
            ...(userData.website && { website: userData.website }),
            ...(userData.kvkNumber && { kvkNumber: userData.kvkNumber }),
            ...(userData.btwNumber && { btwNumber: userData.btwNumber }),
            ...(userData.bankAccount && { bankAccount: userData.bankAccount }),
            ...(userData.bankName && { bankName: userData.bankName }),
            ...(userData.phoneNumber && { phoneNumber: userData.phoneNumber }),
            ...(userData.country && { country: userData.country })
          };
          setCompanyInfo(cleanCompanyInfo);
        }
      } catch (error) {
        console.error('Error loading PDF settings or user profile:', error);
      } finally {
        setLoading(false);
      }
    };
    
    loadData();
  }, []);

  // Prepare order data for PDFPreview in the correct format
  const getOrderPdfProps = () => {
    // Clean up customer data to remove any empty fields
    const cleanCustomerData = {
      name: customer?.company || '',
      ...(customer?.contactPerson && { contactPerson: customer.contactPerson }),
      ...(customer?.name && !customer?.contactPerson && { contactPerson: customer.name }),
      ...(customer?.address && { address: customer.address }),
      ...(customer?.city && { city: customer.city }),
      ...(customer?.postalCode && { postalCode: customer.postalCode }),
      ...(customer?.country && { country: customer.country }),
      ...(customer?.kvkNumber && { kvkNumber: customer.kvkNumber }),
      ...(customer?.btwNumber && { btwNumber: customer.btwNumber })
    };
    
    return {
      companyInfo: companyInfo,
      templateStyle: pdfSettings.template,
      primaryColor: pdfSettings.primaryColor,
      secondaryColor: pdfSettings.secondaryColor,
      showBankAccount: pdfSettings.showBankAccount,
      footerNotes: pdfSettings.footerNotes,
      orderData: {
        orderNumber: order.orderNumber,
        date: new Date(order.date).toLocaleDateString('nl-NL', { 
          day: '2-digit', month: '2-digit', year: 'numeric' 
        }),
        ...(order.project && { project: order.project }),
        ...(order.reference && { reference: order.reference }),
        paymentTerm: `${order.paymentTerm || 14} dagen`,
        items: order.items.map(item => {
          // Calculate price without VAT if BTW is shifted
          const lineTotal = order.btwVerlegd 
            ? item.quantity * item.price 
            : item.quantity * item.price * (1 + item.vat / 100);
          
          return {
            description: item.description || '-',
            quantity: item.quantity,
            unit: item.unit || 'stuk',
            price: `€ ${item.price.toFixed(2)}`,
            vat: order.btwVerlegd ? 'verlegd' : `${item.vat}%`,
            total: `€ ${lineTotal.toFixed(2)}`
          };
        }),
        customer: cleanCustomerData
      },
      // Set this flag to ensure the preview is accurate to the final PDF
      isEmailPreview: true,
      // Pass the BTW verlegd flag if it exists in the order
      btwVerlegd: order.btwVerlegd || false
    };
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div style={{ borderColor: 'var(--color-accent)' }} className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg border p-2" style={{ borderColor: 'var(--color-border)' }}>
      <PDFPreview {...getOrderPdfProps()} />
    </div>
  );
};

export default OrderPDFPreview; 