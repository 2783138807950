// src/components/EmailModal.js
import React, { useState, useEffect } from 'react';
import { X, Send, AlertCircle, Check, Mail, Eye } from 'lucide-react';
import api from '../services/api';
import OrderPDFPreview from './OrderPDFPreview';

const EmailModal = ({ order, customer, onClose, onSuccess }) => {
  const [activeTab, setActiveTab] = useState('compose');
  
  // Debug customer data on component mount
  useEffect(() => {
    console.log('Customer data received:', customer);
    if (customer) {
      console.log('Customer email:', customer.email);
      console.log('Customer name:', customer.name);
      console.log('Customer company:', customer.company);
    }
  }, [customer]);
  
  const [formData, setFormData] = useState({
    recipient: customer?.email || '',
    subject: `Inkooporder #${order?.orderNumber}`,
    message: `Geachte ${customer?.contactPerson || customer?.name},

Hierbij ontvangt u de inkooporder #${order?.orderNumber}.

Met vriendelijke groet,
${localStorage.getItem('userName') || ''} 
${localStorage.getItem('userCompany') || ''}`,
    ccEmail: '',
    includePdf: true
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.recipient) {
      setError('E-mailadres ontvanger is verplicht');
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      const emailData = {
        ...formData,
        includePdf: formData.includePdf
      };
      
      const response = await api.post(`/orders/${order._id}/send-email`, emailData);
      
      if (response.data.success) {
        setSuccess(true);
        if (onSuccess) {
          setTimeout(() => {
            onSuccess(response.data);
          }, 1500);
        }
      }
    } catch (err) {
      console.error('Email sending error:', err);
      setError(err.response?.data?.message || 'Er is een fout opgetreden bij het verzenden van de e-mail');
    } finally {
      setIsLoading(false);
    }
  };

  // If success, show success message
  if (success) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md"
             style={{ backgroundColor: 'var(--color-bg-primary)' }}>
          <div className="flex justify-between items-center p-4 border-b"
               style={{ borderColor: 'var(--color-border)' }}>
            <h3 className="text-lg font-semibold" 
                style={{ color: 'var(--color-text-primary)' }}>
              E-mail verzonden
            </h3>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
              style={{ color: 'var(--color-text-secondary)' }}
            >
              <X size={20} />
            </button>
          </div>
          
          <div className="p-6 text-center">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
              <Check className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2" 
                style={{ color: 'var(--color-text-primary)' }}>
              E-mail succesvol verzonden
            </h3>
            <p className="text-sm text-gray-500 mb-4" 
               style={{ color: 'var(--color-text-secondary)' }}>
              De inkooporder is verzonden naar {formData.recipient}
              {formData.ccEmail && ` en cc naar ${formData.ccEmail}`}
            </p>
            <button
              onClick={onClose}
              style={{ 
                backgroundColor: 'var(--color-accent)', 
                color: 'white' 
              }}
              className="w-full rounded-md py-2 px-4 hover:opacity-90"
            >
              Sluiten
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl overflow-hidden"
           style={{ backgroundColor: 'var(--color-bg-primary)', maxHeight: '90vh' }}>
        <div className="flex justify-between items-center p-4 border-b"
             style={{ borderColor: 'var(--color-border)' }}>
          <h3 className="text-lg font-semibold" 
              style={{ color: 'var(--color-text-primary)' }}>
            Inkooporder #{order.orderNumber} verzenden
          </h3>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
            style={{ color: 'var(--color-text-secondary)' }}
          >
            <X size={20} />
          </button>
        </div>
        
        {/* Tabs */}
        <div className="flex border-b" style={{ borderColor: 'var(--color-border)' }}>
          <button
            onClick={() => setActiveTab('compose')}
            className={`px-4 py-2 flex items-center ${activeTab === 'compose' ? 'border-b-2 font-medium' : 'text-gray-500'}`}
            style={{ 
              borderColor: activeTab === 'compose' ? 'var(--color-accent)' : 'transparent',
              color: activeTab === 'compose' ? 'var(--color-text-primary)' : 'var(--color-text-secondary)'
            }}
          >
            <Mail size={16} className="mr-2" />
            Bericht samenstellen
          </button>
          <button
            onClick={() => setActiveTab('preview')}
            className={`px-4 py-2 flex items-center ${activeTab === 'preview' ? 'border-b-2 font-medium' : 'text-gray-500'}`}
            style={{ 
              borderColor: activeTab === 'preview' ? 'var(--color-accent)' : 'transparent',
              color: activeTab === 'preview' ? 'var(--color-text-primary)' : 'var(--color-text-secondary)'
            }}
          >
            <Eye size={16} className="mr-2" />
            PDF voorbeeld
          </button>
        </div>
        
        {/* Content Area */}
        <div className="overflow-y-auto" style={{ maxHeight: 'calc(90vh - 120px)' }}>
          {activeTab === 'compose' && (
            <form onSubmit={handleSubmit} className="p-6">
              {error && (
                <div className="mb-4 p-3 rounded-md text-sm flex items-start gap-2" 
                    style={{ backgroundColor: 'rgba(220, 38, 38, 0.1)', color: 'var(--color-error)' }}>
                  <AlertCircle className="h-5 w-5 flex-shrink-0" />
                  <span>{error}</span>
                </div>
              )}
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" 
                          style={{ color: 'var(--color-text-secondary)' }}>
                      Aan
                    </label>
                    <input
                      type="email"
                      name="recipient"
                      value={formData.recipient}
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      style={{ 
                        backgroundColor: 'var(--color-input-bg)', 
                        borderColor: 'var(--color-border)',
                        color: 'var(--color-text-primary)'
                      }}
                      placeholder="email@voorbeeld.nl"
                      required
                    />
                    {!formData.recipient && customer && !customer.email && (
                      <p className="text-xs mt-1" style={{ color: 'var(--color-warning)' }}>
                        Geen e-mail gevonden voor deze klant. Vul een e-mailadres in of voeg het toe in klantgegevens.
                      </p>
                    )}
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium mb-1" 
                          style={{ color: 'var(--color-text-secondary)' }}>
                      CC (optioneel)
                    </label>
                    <input
                      type="email"
                      name="ccEmail"
                      value={formData.ccEmail}
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      style={{ 
                        backgroundColor: 'var(--color-input-bg)', 
                        borderColor: 'var(--color-border)',
                        color: 'var(--color-text-primary)'
                      }}
                      placeholder="cc@voorbeeld.nl"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium mb-1" 
                          style={{ color: 'var(--color-text-secondary)' }}>
                      Onderwerp
                    </label>
                    <input
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      style={{ 
                        backgroundColor: 'var(--color-input-bg)', 
                        borderColor: 'var(--color-border)',
                        color: 'var(--color-text-primary)'
                      }}
                      required
                    />
                  </div>
                  
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id="includePdf"
                      name="includePdf"
                      checked={formData.includePdf}
                      onChange={handleChange}
                      className="h-4 w-4 text-blue-600 rounded"
                    />
                    <label htmlFor="includePdf" className="text-sm" style={{ color: 'var(--color-text-secondary)' }}>
                      PDF bijlage toevoegen
                    </label>
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium mb-1" 
                        style={{ color: 'var(--color-text-secondary)' }}>
                    Bericht
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="10"
                    className="w-full p-2 border rounded-md"
                    style={{ 
                      backgroundColor: 'var(--color-input-bg)', 
                      borderColor: 'var(--color-border)',
                      color: 'var(--color-text-primary)',
                      height: '250px'
                    }}
                    required
                  />
                </div>
              </div>
              
              <div className="mt-6 flex justify-end gap-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 border rounded-md"
                  style={{ 
                    backgroundColor: 'var(--color-bg-tertiary)',
                    borderColor: 'var(--color-border)',
                    color: 'var(--color-text-primary)'
                  }}
                  disabled={isLoading}
                >
                  Annuleren
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 rounded-md flex items-center"
                  style={{ 
                    backgroundColor: 'var(--color-accent)', 
                    color: 'white' 
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Verzenden...
                    </>
                  ) : (
                    <>
                      <Send size={16} className="mr-2" />
                      Verzenden
                    </>
                  )}
                </button>
              </div>
            </form>
          )}
          
          {activeTab === 'preview' && (
            <div className="p-6">
              <OrderPDFPreview order={order} customer={customer} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailModal;