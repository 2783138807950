// src/components/PDFPreview.js
import React from 'react';

const PDFPreview = ({ 
  companyInfo, 
  templateStyle = 'classic', 
  primaryColor = '#1E40AF', 
  secondaryColor = '#F3F4F6', 
  showBankAccount = true, 
  footerNotes = '',
  orderData = null, // Add orderData parameter with default to null
  isEmailPreview = false, // Add flag to determine if this is for email preview
  btwVerlegd = false // Add flag for "BTW verlegd" (VAT shifted to customer)
}) => {
  // Format the company details for the footer
  const getFooterText = () => {
    let text = companyInfo.company || '';
    
    if (companyInfo.address) {
      text += text ? ` | ${companyInfo.address}` : companyInfo.address;
    }
    
    if (companyInfo.postalCode || companyInfo.city) {
      const locationText = `${companyInfo.postalCode || ''} ${companyInfo.city || ''}`.trim();
      text += text ? ` | ${locationText}` : locationText;
    }
    
    if (companyInfo.email) {
      text += text ? ` | ${companyInfo.email}` : companyInfo.email;
    }
    
    if (companyInfo.website) {
      text += text ? ` | ${companyInfo.website}` : companyInfo.website;
    }
    
    if (companyInfo.kvkNumber) {
      text += text ? ` | KVK: ${companyInfo.kvkNumber}` : `KVK: ${companyInfo.kvkNumber}`;
    }
    
    if (companyInfo.btwNumber) {
      text += text ? ` | BTW: ${companyInfo.btwNumber}` : `BTW: ${companyInfo.btwNumber}`;
    }
    
    // Add footer notes if provided
    if (footerNotes) {
      text += text ? ` | ${footerNotes}` : footerNotes;
    }
    
    return text;
  };

  // Define a table row with explicit styles for dark text
  const TableRow = ({ description, quantity, unit, price, vat, total, isAlternate }) => {
    // Change background color for alternating rows based on the template style
    const bgColor = isAlternate ? 
      (templateStyle === 'minimal' ? 'white' : '#f9fafb') : 
      'white';
    
    // Change border style based on template
    const borderStyle = templateStyle === 'minimal' ? 
      { borderBottom: '1px solid #e5e7eb', borderLeft: 'none', borderRight: 'none', borderTop: 'none' } : 
      { border: '1px solid #d1d5db' };
    
    // Check if VAT is "verlegd" and style accordingly
    const isVerlegd = typeof vat === 'string' && vat.toLowerCase() === 'verlegd';
    
    return (
      <tr style={{ backgroundColor: bgColor }}>
        <td style={{ padding: '8px', textAlign: 'left', color: '#111827', ...borderStyle }}>
          {description}
        </td>
        <td style={{ padding: '8px', textAlign: 'right', color: '#111827', ...borderStyle }}>
          {quantity}
        </td>
        <td style={{ padding: '8px', textAlign: 'right', color: '#111827', ...borderStyle, display: 'none', '@media (min-width: 768px)': { display: 'table-cell' } }}>
          {unit}
        </td>
        <td style={{ padding: '8px', textAlign: 'right', color: '#111827', ...borderStyle }}>
          {price}
        </td>
        <td style={{ 
          padding: '8px', 
          textAlign: 'right', 
          color: isVerlegd ? '#666666' : '#111827', 
          fontStyle: isVerlegd ? 'italic' : 'normal',
          ...borderStyle, 
          display: 'none', 
          '@media (min-width: 768px)': { display: 'table-cell' } 
        }}>
          {vat}
        </td>
        <td style={{ padding: '8px', textAlign: 'right', color: '#111827', ...borderStyle }}>
          {total}
        </td>
      </tr>
    );
  };

  // Render different header styles based on template
  const renderHeader = () => {
    // Use order customer data if available, otherwise use dummy data
    // For email preview, don't use placeholders for empty fields
    const customerInfo = orderData?.customer || (isEmailPreview ? {} : {
      name: 'Klantnaam BV',
      contactPerson: 'Contactpersoon',
      address: 'Klantadres 123',
      postalCode: '1234 AB',
      city: 'Plaats',
      kvkNumber: '12345678',
      btwNumber: 'NL123456789B01'
    });
    
    // Use order details if available, otherwise use dummy data
    // For email preview, don't use placeholders for empty fields
    const orderDetails = orderData ? {
      orderNumber: orderData.orderNumber || '',
      date: orderData.date || '',
      paymentTerm: orderData.paymentTerm || '',
      project: orderData.project || '',
      reference: orderData.reference || ''
    } : (isEmailPreview ? {} : {
      orderNumber: '2025XXXX',
      date: '01-05-2025',
      paymentTerm: '14 dagen',
      project: 'Voorbeeld Project',
      reference: 'REF123'
    });
    
    // For email preview, hide sections if no data is available
    const hasCustomerInfo = Object.values(customerInfo).some(val => val);
    const hasOrderDetails = Object.values(orderDetails).some(val => val);
    
    switch (templateStyle) {
      case 'modern':
        return (
          <>
            {/* Modern header with color bar */}
            <div style={{ 
              position: 'relative', 
              marginBottom: '24px', 
              paddingTop: '8px' 
            }}>
              {/* Color bar at top */}
              <div style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                right: 0, 
                height: '8px', 
                backgroundColor: primaryColor 
              }}></div>
              
              {/* Company name */}
              <div style={{ marginTop: '16px' }}>
                <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: primaryColor }}>{companyInfo.company}</h2>
              </div>
              
              {/* Two-column layout for company and customer info */}
              <div style={{ 
                display: 'flex', 
                flexDirection: 'column',
                gap: '16px',
                '@media (min-width: 768px)': {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '24px'
                },
                marginTop: '16px'
              }}>
                {/* Company info - only show if we have data */}
                {(!isEmailPreview || Object.keys(companyInfo).length > 0) && (
                  <div style={{ flex: 1 }}>
                    <h3 style={{ fontSize: '13px', fontWeight: 'bold', color: '#666666', marginBottom: '8px' }}>BEDRIJFSINFORMATIE</h3>
                    <div style={{ fontSize: '12px', color: '#333333' }}>
                      {companyInfo.name && <p>{companyInfo.name}</p>}
                      {companyInfo.address && <p>{companyInfo.address}</p>}
                      {(companyInfo.postalCode || companyInfo.city) && 
                        <p>{companyInfo.postalCode} {companyInfo.city}</p>
                      }
                      {companyInfo.country && <p>{companyInfo.country}</p>}
                      {companyInfo.email && <p>Email: {companyInfo.email}</p>}
                      {companyInfo.phoneNumber && <p>Tel: {companyInfo.phoneNumber}</p>}
                      {companyInfo.website && <p>Website: {companyInfo.website}</p>}
                    </div>
                  </div>
                )}
                
                {/* Customer info - only show if we have data */}
                {(!isEmailPreview || hasCustomerInfo) && (
                  <div style={{ flex: 1 }}>
                    <h3 style={{ fontSize: '13px', fontWeight: 'bold', color: '#666666', marginBottom: '8px' }}>FACTUUR AAN</h3>
                    <div style={{ fontSize: '12px', color: '#333333' }}>
                      {customerInfo.name && <p>{customerInfo.name}</p>}
                      {customerInfo.contactPerson && <p>t.a.v. {customerInfo.contactPerson}</p>}
                      {customerInfo.address && <p>{customerInfo.address}</p>}
                      {(customerInfo.postalCode || customerInfo.city) && <p>{customerInfo.postalCode} {customerInfo.city}</p>}
                      {customerInfo.kvkNumber && <p>KVK: {customerInfo.kvkNumber}</p>}
                      {customerInfo.btwNumber && <p>BTW: {customerInfo.btwNumber}</p>}
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            {/* Document title with accent bar */}
            <div style={{ 
              position: 'relative', 
              marginTop: '12px',
              marginBottom: '16px',
              paddingLeft: '16px',
              height: '22px'
            }}>
              {/* Accent rectangle */}
              <div style={{ 
                position: 'absolute', 
                left: 0, 
                top: 0, 
                width: '8px', 
                height: '22px', 
                backgroundColor: primaryColor 
              }}></div>
              
              {/* Title */}
              <h2 style={{ 
                fontSize: '18px', 
                fontWeight: 'bold', 
                color: '#333333',
                lineHeight: '22px'
              }}>INKOOPORDER</h2>
            </div>
            
            {/* Order details in vertical layout - only show if we have details */}
            {(!isEmailPreview || hasOrderDetails) && (
              <div style={{ 
                marginTop: '16px', 
                marginBottom: '16px', 
                fontSize: '13px', 
                color: '#333333' 
              }}>
                {/* Order Number */}
                {orderDetails.orderNumber && (
                  <div style={{ display: 'flex', marginBottom: '8px' }}>
                    <div style={{ width: '120px', fontWeight: 'bold', color: '#666666' }}>Ordernummer:</div>
                    <div>{orderDetails.orderNumber}</div>
                  </div>
                )}
                
                {/* Date */}
                {orderDetails.date && (
                  <div style={{ display: 'flex', marginBottom: '8px' }}>
                    <div style={{ width: '120px', fontWeight: 'bold', color: '#666666' }}>Datum:</div>
                    <div>{orderDetails.date}</div>
                  </div>
                )}
                
                {/* Payment Term */}
                {orderDetails.paymentTerm && (
                  <div style={{ display: 'flex', marginBottom: '8px' }}>
                    <div style={{ width: '120px', fontWeight: 'bold', color: '#666666' }}>Betaaltermijn:</div>
                    <div>{orderDetails.paymentTerm}</div>
                  </div>
                )}
                
                {/* Project */}
                {orderDetails.project && (
                  <div style={{ display: 'flex', marginBottom: '8px' }}>
                    <div style={{ width: '120px', fontWeight: 'bold', color: '#666666' }}>Project:</div>
                    <div>{orderDetails.project}</div>
                  </div>
                )}
                
                {/* Reference */}
                {orderDetails.reference && (
                  <div style={{ display: 'flex', marginBottom: '8px' }}>
                    <div style={{ width: '120px', fontWeight: 'bold', color: '#666666' }}>Referentie:</div>
                    <div>{orderDetails.reference}</div>
                  </div>
                )}
              </div>
            )}
            
            {/* Separator line before items table */}
            <div style={{ 
              borderBottom: '1px solid #E5E7EB', 
              marginBottom: '16px' 
            }}></div>
          </>
        );
        
      case 'minimal':
        return (
          <>
            {/* Minimal header */}
            <div style={{ marginBottom: '24px' }}>
              <div style={{ 
                display: 'flex', 
                flexDirection: 'column',
                '@media (min-width: 768px)': {
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                },
                marginBottom: '16px',
                gap: '12px'
              }}>
                <div>
                  <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: primaryColor }}>{companyInfo.company}</h2>
                  <h3 style={{ fontSize: '16px', color: primaryColor, marginTop: '4px' }}>INKOOPORDER</h3>
                </div>
                <div style={{ textAlign: 'left', '@media (min-width: 768px)': { textAlign: 'right' } }}>
                  <h3 style={{ fontWeight: 'bold', fontSize: '14px', color: '#111827' }}>FACTUUR AAN:</h3>
                  <div style={{ marginTop: '4px', fontSize: '13px', color: '#4b5563', textAlign: 'left', '@media (min-width: 768px)': { textAlign: 'right' } }}>
                    <p>Klantnaam BV</p>
                    <p>t.a.v. Contactpersoon</p>
                  </div>
                </div>
              </div>
              
              {/* Compact order details */}
              <div style={{ fontSize: '13px', color: '#4b5563', marginBottom: '16px' }}>
                <p style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                  Ordernummer: 2025XXXX | Datum: 01-05-2025 | Referentie: REF123 | Betaaltermijn: 14 dagen
                </p>
              </div>
              
              <div style={{ borderBottom: '1px solid #e5e7eb', paddingBottom: '8px' }}></div>
            </div>
          </>
        );
        
      default: // Classic
        return (
          <>
            {/* Classic header */}
            <div className="mb-6">
              <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: primaryColor }}>{companyInfo.company}</h2>
              {(!isEmailPreview || Object.keys(companyInfo).length > 0) && (
                <div style={{ marginTop: '16px', fontSize: '14px', color: '#4b5563' }}>
                  {companyInfo.name && <p>{companyInfo.name}</p>}
                  {companyInfo.address && <p>{companyInfo.address}</p>}
                  {(companyInfo.postalCode || companyInfo.city) && 
                    <p>{companyInfo.postalCode} {companyInfo.city}</p>
                  }
                  {companyInfo.country && <p>{companyInfo.country}</p>}
                  {companyInfo.email && <p>Email: {companyInfo.email}</p>}
                  {companyInfo.phoneNumber && <p>Tel: {companyInfo.phoneNumber}</p>}
                  {companyInfo.website && <p>Website: {companyInfo.website}</p>}
                </div>
              )}
            </div>
            
            {(!isEmailPreview || hasCustomerInfo) && (
              <div style={{ marginTop: '32px' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: '1' }}></div>
                  <div style={{ width: '256px' }}>
                    <h3 style={{ fontWeight: 'bold', fontSize: '16px', color: '#111827' }}>FACTUUR AAN:</h3>
                    <div style={{ marginTop: '8px', fontSize: '14px', color: '#4b5563' }}>
                      {customerInfo.name && <p>{customerInfo.name}</p>}
                      {customerInfo.contactPerson && <p>t.a.v. {customerInfo.contactPerson}</p>}
                      {customerInfo.address && <p>{customerInfo.address}</p>}
                      {(customerInfo.postalCode || customerInfo.city) && 
                        <p>{customerInfo.postalCode} {customerInfo.city}</p>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
            
            {/* Order Header */}
            <div style={{ marginTop: '32px' }}>
              <h2 style={{ fontSize: '20px', fontWeight: 'bold', color: primaryColor }}>INKOOPORDER</h2>
            </div>
            
            {/* Order Details */}
            {(!isEmailPreview || hasOrderDetails) && (
              <div style={{ marginTop: '16px', fontSize: '14px', color: '#111827' }}>
                <div style={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  '@media (min-width: 768px)': {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '8px'
                  }
                }}>
                  {orderDetails.orderNumber && (
                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                      <p style={{ fontWeight: 'bold', width: '128px', color: '#111827' }}>Ordernummer:</p>
                      <p style={{ color: '#111827' }}>{orderDetails.orderNumber}</p>
                    </div>
                  )}
                  {orderDetails.reference && (
                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                      <p style={{ fontWeight: 'bold', width: '128px', color: '#111827' }}>Referentie:</p>
                      <p style={{ color: '#111827' }}>{orderDetails.reference}</p>
                    </div>
                  )}
                  {orderDetails.date && (
                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                      <p style={{ fontWeight: 'bold', width: '128px', color: '#111827' }}>Datum:</p>
                      <p style={{ color: '#111827' }}>{orderDetails.date}</p>
                    </div>
                  )}
                  {orderDetails.project && (
                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                      <p style={{ fontWeight: 'bold', width: '128px', color: '#111827' }}>Project:</p>
                      <p style={{ color: '#111827' }}>{orderDetails.project}</p>
                    </div>
                  )}
                  {orderDetails.paymentTerm && (
                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                      <p style={{ fontWeight: 'bold', width: '128px', color: '#111827' }}>Betaaltermijn:</p>
                      <p style={{ color: '#111827' }}>{orderDetails.paymentTerm}</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        );
    }
  };

  // Render different table header styles based on template
  const renderTableHeader = () => {
    const commonThStyle = { 
      padding: '8px', 
      fontWeight: 'bold',
      color: '#111827'
    };
    
    // Customize based on template style
    switch (templateStyle) {
      case 'modern':
        return (
          <tr>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'left',
              borderRadius: '2px 0 0 2px'
            }}>
              Omschrijving
            </th>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'right' 
            }}>
              Aantal
            </th>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'right',
              display: 'none',
              '@media (min-width: 768px)': { display: 'table-cell' }
            }}>
              Eenheid
            </th>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'right' 
            }}>
              Prijs (€)
            </th>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'right',
              display: 'none',
              '@media (min-width: 768px)': { display: 'table-cell' }
            }}>
              BTW
            </th>
            <th style={{ 
              ...commonThStyle, 
              backgroundColor: primaryColor,
              color: 'white',
              textAlign: 'right',
              borderRadius: '0 2px 2px 0'
            }}>
              Totaal (€)
            </th>
          </tr>
        );
        
      case 'minimal':
        return (
          <tr style={{ borderBottom: '1px solid #e5e7eb' }}>
            <th style={{ ...commonThStyle, textAlign: 'left', border: 'none' }}>Omschrijving</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: 'none' }}>Aantal</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: 'none', display: 'none', '@media (min-width: 768px)': { display: 'table-cell' } }}>Eenheid</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: 'none' }}>Prijs (€)</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: 'none', display: 'none', '@media (min-width: 768px)': { display: 'table-cell' } }}>BTW</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: 'none' }}>Totaal (€)</th>
          </tr>
        );
        
      default: // Classic
        return (
          <tr style={{ backgroundColor: secondaryColor }}>
            <th style={{ ...commonThStyle, textAlign: 'left', border: '1px solid #d1d5db' }}>Omschrijving</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: '1px solid #d1d5db' }}>Aantal</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: '1px solid #d1d5db', display: 'none', '@media (min-width: 768px)': { display: 'table-cell' } }}>Eenheid</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: '1px solid #d1d5db' }}>Prijs (€)</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: '1px solid #d1d5db', display: 'none', '@media (min-width: 768px)': { display: 'table-cell' } }}>BTW</th>
            <th style={{ ...commonThStyle, textAlign: 'right', border: '1px solid #d1d5db' }}>Totaal (€)</th>
          </tr>
        );
    }
  };

  // Render totals section based on template
  const renderTotals = () => {
    // Calculate totals based on order items or use sample values
    let subtotal = 0;
    let vatTotal = 0;
    let total = 0;
    
    if (orderData && orderData.items) {
      // Calculate from real order data
      orderData.items.forEach(item => {
        const itemPrice = parseFloat(String(item.price).replace('€', '').replace(',', '.'));
        const itemVat = parseFloat(String(item.vat).replace('%', ''));
        const itemQuantity = parseFloat(String(item.quantity));
        
        const lineSubtotal = itemPrice * itemQuantity;
        
        // If BTW is shifted to customer, don't include VAT in the total
        const lineVat = btwVerlegd ? 0 : lineSubtotal * (itemVat / 100);
        
        subtotal += lineSubtotal;
        vatTotal += lineVat;
      });
      
      total = subtotal + vatTotal;
    } else {
      // Sample totals
      subtotal = 345.00;
      vatTotal = btwVerlegd ? 0 : 72.45;
      total = subtotal + vatTotal;
    }
    
    // Format as currency
    const formatCurrency = (amount) => {
      return `€ ${amount.toFixed(2).replace('.', ',')}`;
    };
    
    // Render based on template style
    switch (templateStyle) {
      case 'modern':
        return (
          <div style={{ 
            marginTop: '24px', 
            display: 'flex', 
            justifyContent: 'flex-end' 
          }}>
            <div style={{ 
              width: '280px',
              borderTop: '2px solid #e5e7eb',
              paddingTop: '16px',
              fontSize: '14px',
              color: '#333333',
            }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span>Subtotaal:</span>
                <span style={{ fontWeight: 'medium' }}>{formatCurrency(subtotal)}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span>BTW:</span>
                <span style={{ fontWeight: 'medium' }}>
                  {btwVerlegd ? 'Verlegd' : formatCurrency(vatTotal)}
                </span>
              </div>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                fontWeight: 'bold',
                fontSize: '16px',
                color: '#111827',
                marginTop: '16px'
              }}>
                <span>Totaal:</span>
                <span>{formatCurrency(total)}</span>
              </div>
            </div>
          </div>
        );
        
      case 'minimal':
        return (
          <div style={{ marginTop: '32px', textAlign: 'right' }}>
            <p style={{ fontSize: '14px', color: '#374151', marginBottom: '4px' }}>Subtotaal: € 425.00</p>
            <p style={{ fontSize: '14px', color: '#374151', marginBottom: '4px' }}>
              BTW: {btwVerlegd ? 'Verlegd' : '€ 89.25'}
            </p>
            <p style={{ fontSize: '16px', fontWeight: 'bold', color: primaryColor, marginTop: '8px' }}>Totaal: € 514.25</p>
          </div>
        );
        
      default: // Classic
        return (
          <div style={{ marginTop: '32px', display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ width: '256px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
                <span style={{ fontSize: '14px', fontWeight: '500', color: '#374151' }}>Subtotaal:</span>
                <span style={{ fontSize: '14px', color: '#374151' }}>€ 425.00</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
                <span style={{ fontSize: '14px', fontWeight: '500', color: '#374151' }}>BTW:</span>
                <span style={{ fontSize: '14px', color: '#374151' }}>
                  {btwVerlegd ? 'Verlegd' : '€ 89.25'}
                </span>
              </div>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                fontWeight: 'bold', 
                borderTop: '1px solid #9ca3af', 
                paddingTop: '4px', 
                marginTop: '4px' 
              }}>
                <span style={{ color: primaryColor }}>Totaal:</span>
                <span style={{ color: primaryColor }}>€ 514.25</span>
              </div>
            </div>
          </div>
        );
    }
  };

  // Render footer based on template
  const renderFooter = () => {
    const footerStyle = {
      marginTop: '64px', 
      paddingTop: '16px', 
      borderTop: '1px solid #d1d5db', 
      textAlign: 'center', 
      fontSize: '11px', 
      color: '#6b7280',
      overflowWrap: 'break-word',
      wordBreak: 'break-word'
    };
    
    // Bank account info if enabled and we have bank account data
    const bankInfo = showBankAccount && companyInfo.bankAccount ? (
      <p style={{ marginTop: '8px' }}>IBAN: {companyInfo.bankAccount} {companyInfo.bankName ? `| BIC: ${companyInfo.bankName}` : ''}</p>
    ) : null;
    
    const footerText = getFooterText();
    
    return (
      <div style={footerStyle}>
        {footerText && <p>{footerText}</p>}
        {bankInfo}
      </div>
    );
  };

  return (
    <div className="border rounded-lg overflow-hidden" style={{ backgroundColor: 'white', width: '100%', maxWidth: '800px', margin: '0 auto', boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)' }}>
      <div className="p-6">
        {/* Header section */}
        {renderHeader()}
        
        {/* Items Table */}
        <div style={{ overflowX: 'auto', width: '100%' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed', fontSize: '13px' }}>
            {renderTableHeader()}
            <tbody>
              {orderData && orderData.items ? (
                // Real items if order data is provided
                orderData.items.map((item, index) => (
                  <TableRow
                    key={index}
                    description={item.description}
                    quantity={item.quantity}
                    unit={item.unit}
                    price={item.price}
                    vat={item.vat}
                    total={item.total}
                    isAlternate={index % 2 === 1}
                  />
                ))
              ) : (
                // Sample items for preview - don't show if this is email preview
                !isEmailPreview && (
                  <>
                    <TableRow
                      description="Voorbeeld product of dienst 1"
                      quantity="2"
                      unit="stuk"
                      price="€ 100,00"
                      vat="21%"
                      total="€ 242,00"
                      isAlternate={false}
                    />
                    <TableRow
                      description="Voorbeeld product of dienst 2 met een langere beschrijving om de werking van tekstoverflow te testen in de tabel"
                      quantity="1"
                      unit="uur"
                      price="€ 75,00"
                      vat="21%"
                      total="€ 90,75"
                      isAlternate={true}
                    />
                    <TableRow
                      description="Voorbeeld product of dienst 3"
                      quantity="5"
                      unit="meter"
                      price="€ 10,00"
                      vat="21%"
                      total="€ 60,50"
                      isAlternate={false}
                    />
                  </>
                )
              )}
            </tbody>
          </table>
        </div>
        
        {/* Totals */}
        {renderTotals()}
      </div>
      
      {/* Footer */}
      {renderFooter()}
    </div>
  );
};

export default PDFPreview;