// src/pages/NieuweOrder.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Save, X, Plus, Trash2 } from 'lucide-react';
import { createOrder, getOrder, updateOrder } from '../services/orderService';
import { getCustomers } from '../services/customerService';
import { getOrderNumberSettings } from '../services/authService';

const NieuweOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEditing = !!id;
  
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  const [orderData, setOrderData] = useState({
    orderNumber: '',
    date: new Date().toISOString().substr(0, 10),
    customer: '',
    project: '',
    reference: '',
    paymentTerm: '14',
    items: [
      { 
        description: '', 
        quantity: 1, 
        unit: 'stuk', 
        price: 0, 
        vat: 21 
      }
    ],
    status: 'concept',
    btwVerlegd: false
  });

  // Fetch customers and order data if editing, or generate preview order number if new
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch customers
        const customersData = await getCustomers();
        setCustomers(customersData);
        
        // If editing, fetch order data
        if (isEditing && id) {
          const orderData = await getOrder(id);
          
          // Format date for input field
          if (orderData.date) {
            orderData.date = new Date(orderData.date).toISOString().substr(0, 10);
          }
          
          // Handle customer ID
          if (orderData.customer && typeof orderData.customer === 'object') {
            orderData.customer = orderData.customer._id;
          }
          
          setOrderData(orderData);
        } else {
          // If creating a new order, generate a preview of what the order number will be
          try {
            const settings = await getOrderNumberSettings();
            const year = new Date().getFullYear();
            let previewNumber = '';
            
            if (settings.prefix) {
              previewNumber += settings.prefix;
            }
            
            if (settings.includeYear) {
              previewNumber += year;
            }
            
            // Add the next number (using startingNumber as the best guess)
            // The actual number will be generated by the server
            previewNumber += settings.startingNumber;
            
            setOrderData(prev => ({
              ...prev,
              orderNumber: previewNumber
            }));
          } catch (err) {
            console.error('Error generating preview order number:', err);
            setOrderData(prev => ({
              ...prev,
              orderNumber: 'Wordt automatisch gegenereerd'
            }));
          }
        }
        
      } catch (err) {
        setError('Er is een fout opgetreden bij het ophalen van de gegevens.');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [id, isEditing]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setOrderData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleItemChange = (id, field, value) => {
    setOrderData(prev => ({
      ...prev,
      items: prev.items.map((item, index) => 
        index === id ? { ...item, [field]: field === 'quantity' || field === 'price' || field === 'vat' ? Number(value) : value } : item
      )
    }));
  };

  const addItem = () => {
    setOrderData(prev => ({
      ...prev,
      items: [
        ...prev.items, 
        { description: '', quantity: 1, unit: 'stuk', price: 0, vat: 21 }
      ]
    }));
  };

  const removeItem = (index) => {
    if (orderData.items.length <= 1) return;
    
    setOrderData(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Validate required fields
      if (!orderData.customer) {
        setError('Selecteer een klant');
        return;
      }
      
      if (!orderData.items || orderData.items.length === 0) {
        setError('Voeg minimaal één product toe');
        return;
      }
      
      // Create a simplified order payload
      const simplifiedOrderData = {
        customer: orderData.customer,
        date: new Date().toISOString(),
        project: orderData.project,
        reference: orderData.reference,
        items: orderData.items.map(item => ({
          description: item.description || 'Product',
          quantity: Number(item.quantity) || 1,
          unit: item.unit || 'stuk',
          price: Number(item.price) || 0,
          vat: Number(item.vat) || 21
        })),
        status: orderData.status || 'concept',
        paymentTerm: Number(orderData.paymentTerm) || 14
      };
      
      // Log what we're about to send
      console.log('Preparing to submit order with data:', JSON.stringify(simplifiedOrderData, null, 2));
      
      setIsSaving(true);
      setError('');
      
      let savedOrder;
      
      if (isEditing) {
        savedOrder = await updateOrder(id, simplifiedOrderData);
      } else {
        // Send simplified data
        savedOrder = await createOrder(simplifiedOrderData);
        
        // Show a success message with the actual order number
        setError('');
        setSuccess(`Order ${savedOrder.orderNumber} is succesvol aangemaakt!`);
        
        // Wait a moment before redirecting so user can see the success message
        setTimeout(() => {
          navigate('/inkooporders');
        }, 2000);
        
        return; // Exit to prevent immediate navigation
      }
      
      navigate('/inkooporders');
    } catch (err) {
      console.error('Error saving order:', err);
      
      // Handle different error types
      if (err.errors && Object.keys(err.errors).length > 0) {
        // Format validation errors into a readable message
        const errorMessages = Object.entries(err.errors)
          .map(([field, message]) => `${field}: ${message}`)
          .join('\n');
        
        setError(`Validatiefout:\n${errorMessages}`);
      } else if (err.message) {
        setError(err.message);
      } else if (typeof err === 'string') {
        setError(err);
      } else {
        setError('Er is een fout opgetreden bij het opslaan van de order.');
      }
    } finally {
      setIsSaving(false);
    }
  };

  // Calculate subtotal
  const calculateSubtotal = () => {
    return orderData.items.reduce((total, item) => {
      return total + (Number(item.quantity) * Number(item.price));
    }, 0);
  };

  // Calculate VAT
  const calculateBTW = () => {
    if (orderData.btwVerlegd) {
      return 0;
    }
    
    return orderData.items.reduce((total, item) => {
      const itemTotal = Number(item.quantity) * Number(item.price);
      return total + (itemTotal * (Number(item.vat) / 100));
    }, 0);
  };

  // Calculate total
  const calculateTotal = () => {
    return calculateSubtotal() + calculateBTW();
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div style={{ borderColor: 'var(--color-accent)' }} className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2"></div>
      </div>
    );
  }

  return (
    <>
      {/* Header */}
      <header style={{ backgroundColor: 'var(--color-bg-primary)', borderBottom: '1px solid var(--color-border)' }} className="p-4 flex flex-col sm:flex-row justify-between items-center gap-4">
        <h2 style={{ color: 'var(--color-text-primary)' }} className="text-2xl font-semibold">
          {isEditing ? 'Inkooporder bewerken' : 'Nieuwe Inkooporder'}
        </h2>
        <div className="flex flex-wrap gap-2 w-full sm:w-auto">
          <button 
            onClick={handleSubmit}
            disabled={isSaving}
            style={{ backgroundColor: 'var(--color-accent)', color: 'white' }}
            className="w-full sm:w-auto px-4 py-2 rounded-md flex items-center justify-center"
          >
            <Save className="mr-2" size={18} /> 
            {isSaving ? 'Bezig met opslaan...' : 'Opslaan'}
          </button>
          <Link 
            to="/inkooporders" 
            style={{ backgroundColor: 'var(--color-bg-tertiary)', color: 'var(--color-text-primary)' }}
            className="w-full sm:w-auto px-4 py-2 rounded-md flex items-center justify-center"
          >
            <X className="mr-2" size={18} /> Annuleren
          </Link>
        </div>
      </header>
      
      {/* Content Area */}
      <main style={{ backgroundColor: 'var(--color-bg-secondary)' }} className="flex-1 overflow-x-hidden overflow-y-auto p-4 sm:p-6">
        {error && (
          <div style={{ 
            backgroundColor: 'rgba(220, 38, 38, 0.1)',
            borderColor: 'var(--color-error)',
            color: 'var(--color-error)'
          }} className="border rounded mb-6 px-4 py-3 whitespace-pre-line">
            {error}
          </div>
        )}
        
        {success && (
          <div style={{ 
            backgroundColor: 'rgba(16, 185, 129, 0.1)',
            borderColor: 'var(--color-success)',
            color: 'var(--color-success)'
          }} className="border rounded mb-6 px-4 py-3">
            {success}
          </div>
        )}
        
        <form onSubmit={handleSubmit}>
          <div style={{ backgroundColor: 'var(--color-bg-primary)' }} className="shadow-md rounded-lg overflow-hidden mb-6">
            <div style={{ backgroundColor: 'var(--color-bg-tertiary)', borderBottom: '1px solid var(--color-border)' }} className="p-4">
              <h3 style={{ color: 'var(--color-text-primary)' }} className="text-lg font-semibold">Ordergegevens</h3>
            </div>
            
            <div className="p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Ordernummer</label>
                  <input 
                    type="text" 
                    name="orderNumber"
                    value={orderData.orderNumber}
                    readOnly={true}
                    style={{ 
                      backgroundColor: 'var(--color-bg-tertiary)',
                      borderColor: 'var(--color-input-border)',
                      color: 'var(--color-text-primary)',
                      cursor: 'not-allowed',
                      opacity: 0.9
                    }}
                    className="w-full border rounded-md px-3 py-2 focus:outline-none" 
                  />
                  {!isEditing && (
                    <p style={{ color: 'var(--color-text-tertiary)' }} className="text-xs mt-1">
                      Voorbeeldnummer gebaseerd op uw instellingen. Het definitieve ordernummer wordt toegekend bij opslaan.
                    </p>
                  )}
                </div>
                
                <div>
                  <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Datum</label>
                  <input 
                    type="date" 
                    name="date"
                    value={orderData.date}
                    onChange={handleChange}
                    style={{ 
                      backgroundColor: 'var(--color-input-bg)',
                      borderColor: 'var(--color-input-border)',
                      color: 'var(--color-text-primary)'
                    }}
                    className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                  />
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Klant</label>
                  <select 
                    name="customer"
                    value={orderData.customer}
                    onChange={handleChange}
                    style={{ 
                      backgroundColor: 'var(--color-input-bg)',
                      borderColor: 'var(--color-input-border)',
                      color: 'var(--color-text-primary)'
                    }}
                    className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                  >
                    <option value="">Selecteer een klant</option>
                    {customers.map(klant => (
                      <option key={klant._id} value={klant._id}>
                        {klant.company} ({klant.name})
                      </option>
                    ))}
                  </select>
                </div>
                
                <div>
                  <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Project</label>
                  <input 
                    type="text" 
                    name="project"
                    value={orderData.project}
                    onChange={handleChange}
                    style={{ 
                      backgroundColor: 'var(--color-input-bg)',
                      borderColor: 'var(--color-input-border)',
                      color: 'var(--color-text-primary)'
                    }}
                    className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                  />
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Referentie</label>
                  <input 
                    type="text" 
                    name="reference"
                    value={orderData.reference}
                    onChange={handleChange}
                    style={{ 
                      backgroundColor: 'var(--color-input-bg)',
                      borderColor: 'var(--color-input-border)',
                      color: 'var(--color-text-primary)'
                    }}
                    className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                  />
                </div>
                
                <div>
                  <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Betaaltermijn (dagen)</label>
                  <select 
                    name="paymentTerm"
                    value={orderData.paymentTerm}
                    onChange={handleChange}
                    style={{ 
                      backgroundColor: 'var(--color-input-bg)',
                      borderColor: 'var(--color-input-border)',
                      color: 'var(--color-text-primary)'
                    }}
                    className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                  >
                    <option value="14">14 dagen</option>
                    <option value="30">30 dagen</option>
                    <option value="60">60 dagen</option>
                  </select>
                </div>
              </div>
              
              <div className="mt-4">
                <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Status</label>
                <select 
                  name="status"
                  value={orderData.status}
                  onChange={handleChange}
                  style={{ 
                    backgroundColor: 'var(--color-input-bg)',
                    borderColor: 'var(--color-input-border)',
                    color: 'var(--color-text-primary)'
                  }}
                  className="w-full md:w-1/3 border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                >
                  <option value="concept">Concept</option>
                  <option value="sent">Verzonden</option>
                  <option value="paid">Betaald</option>
                  <option value="cancelled">Geannuleerd</option>
                </select>
              </div>
            </div>
          </div>
          
          <div style={{ backgroundColor: 'var(--color-bg-primary)' }} className="shadow-md rounded-lg overflow-hidden">
            <div style={{ backgroundColor: 'var(--color-bg-tertiary)', borderBottom: '1px solid var(--color-border)' }} className="p-4 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3">
              <h3 style={{ color: 'var(--color-text-primary)' }} className="text-lg font-semibold">Orderregels</h3>
              <button 
                type="button"
                onClick={addItem}
                style={{ backgroundColor: 'var(--color-accent)', color: 'white' }}
                className="px-3 py-1 rounded-md flex items-center text-sm"
              >
                <Plus className="mr-1" size={16} /> Item Toevoegen
              </button>
            </div>
            
            <div className="p-4">
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead style={{ backgroundColor: 'var(--color-bg-tertiary)' }}>
                    <tr>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="px-2 sm:px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Omschrijving</th>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="px-2 sm:px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Aantal</th>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="hidden sm:table-cell px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Eenheid</th>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="px-2 sm:px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Prijs (€)</th>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="hidden sm:table-cell px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">BTW %</th>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="px-2 sm:px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Totaal</th>
                      <th style={{ color: 'var(--color-text-secondary)' }} className="px-2 sm:px-4 py-2 text-center text-xs font-medium uppercase tracking-wider">Acties</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData.items.map((item, index) => (
                      <tr key={index} style={{ borderBottom: '1px solid var(--color-border)' }}>
                        <td className="px-2 sm:px-4 py-2">
                          <input 
                            type="text" 
                            value={item.description}
                            onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                            style={{ 
                              backgroundColor: 'var(--color-input-bg)',
                              borderColor: 'var(--color-input-border)',
                              color: 'var(--color-text-primary)'
                            }}
                            className="w-full border rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                          />
                        </td>
                        <td className="px-2 sm:px-4 py-2">
                          <input 
                            type="number" 
                            value={item.quantity}
                            onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                            style={{ 
                              backgroundColor: 'var(--color-input-bg)',
                              borderColor: 'var(--color-input-border)',
                              color: 'var(--color-text-primary)'
                            }}
                            className="w-16 sm:w-20 border rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                          />
                        </td>
                        <td className="hidden sm:table-cell px-4 py-2">
                          <select 
                            value={item.unit}
                            onChange={(e) => handleItemChange(index, 'unit', e.target.value)}
                            style={{ 
                              backgroundColor: 'var(--color-input-bg)',
                              borderColor: 'var(--color-input-border)',
                              color: 'var(--color-text-primary)'
                            }}
                            className="border rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                          >
                            <option value="stuk">stuk</option>
                            <option value="uur">uur</option>
                            <option value="dag">dag</option>
                            <option value="meter">meter</option>
                            <option value="m2">m²</option>
                          </select>
                        </td>
                        <td className="px-2 sm:px-4 py-2">
                          <input 
                            type="number" 
                            value={item.price}
                            onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                            style={{ 
                              backgroundColor: 'var(--color-input-bg)',
                              borderColor: 'var(--color-input-border)',
                              color: 'var(--color-text-primary)'
                            }}
                            className="w-20 sm:w-24 border rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                          />
                        </td>
                        <td className="hidden sm:table-cell px-4 py-2">
                          <select 
                            value={item.vat}
                            onChange={(e) => handleItemChange(index, 'vat', e.target.value)}
                            style={{ 
                              backgroundColor: 'var(--color-input-bg)',
                              borderColor: 'var(--color-input-border)',
                              color: 'var(--color-text-primary)'
                            }}
                            className="border rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" 
                          >
                            <option value="0">0%</option>
                            <option value="9">9%</option>
                            <option value="21">21%</option>
                          </select>
                        </td>
                        <td style={{ color: 'var(--color-text-primary)' }} className="px-2 sm:px-4 py-2 font-medium">
                          € {(item.quantity * item.price).toFixed(2)}
                        </td>
                        <td className="px-2 sm:px-4 py-2 text-center">
                          <button 
                            type="button"
                            onClick={() => removeItem(index)}
                            disabled={orderData.items.length <= 1}
                            style={{ color: 'var(--color-error)' }}
                            className={`hover:text-red-700 p-1 rounded-md hover:bg-red-50 ${
                              orderData.items.length <= 1 ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                          >
                            <Trash2 size={16} className="sm:w-[18px] sm:h-[18px]" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
              <div style={{ borderTop: '1px solid var(--color-border)' }} className="mt-6 pt-4">
                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <div className="mb-4 sm:mb-0">
                    <label className="flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        name="btwVerlegd"
                        checked={orderData.btwVerlegd}
                        onChange={handleChange}
                        className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                        style={{ 
                          accentColor: 'var(--color-accent)',
                          borderColor: 'var(--color-input-border)',
                        }}
                      />
                      <span className="ml-2" style={{ color: 'var(--color-text-primary)' }}>BTW verlegd naar klant</span>
                    </label>
                  </div>
                  <div className="w-full sm:w-64">
                    <div className="flex justify-between py-2">
                      <span style={{ color: 'var(--color-text-secondary)' }}>Subtotaal:</span>
                      <span style={{ color: 'var(--color-text-primary)' }} className="font-medium">€ {calculateSubtotal().toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between py-2">
                      <span style={{ color: 'var(--color-text-secondary)' }}>BTW:</span>
                      <span style={{ color: 'var(--color-text-primary)' }} className="font-medium">
                        {orderData.btwVerlegd ? 'Verlegd' : `€ ${calculateBTW().toFixed(2)}`}
                      </span>
                    </div>
                    <div style={{ borderTop: '1px solid var(--color-border)' }} className="flex justify-between py-2 text-lg font-bold">
                      <span style={{ color: 'var(--color-text-primary)' }}>Totaal:</span>
                      <span style={{ color: 'var(--color-text-primary)' }}>€ {calculateTotal().toFixed(2)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </>
  );
};

export default NieuweOrder;