import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckCircle, XCircle, AlertTriangle, ArrowRight } from 'lucide-react';
import api from '../services/api';
import { useTheme } from '../context/ThemeContext';

// Simple confetti animation component
const Confetti = () => {
  const [particles, setParticles] = useState([]);
  
  useEffect(() => {
    // Create 50 particles with random properties
    const newParticles = Array.from({ length: 50 }, (_, i) => ({
      id: i,
      x: Math.random() * 100,
      y: -20 - Math.random() * 100,
      size: 5 + Math.random() * 10,
      color: ['#1a73e8', '#4285f4', '#fbbc04', '#34a853', '#ea4335'][Math.floor(Math.random() * 5)],
      rotation: Math.random() * 360,
      speed: 1 + Math.random() * 3
    }));
    
    setParticles(newParticles);
    
    // Animation loop
    const interval = setInterval(() => {
      setParticles(prevParticles => 
        prevParticles.map(p => ({
          ...p,
          y: p.y + p.speed,
          rotation: p.rotation + 2
        })).filter(p => p.y < 120) // Remove particles that fall off screen
      );
    }, 50);
    
    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="fixed inset-0 pointer-events-none overflow-hidden" style={{ zIndex: 10 }}>
      {particles.map((p) => (
        <div 
          key={p.id}
          className="absolute"
          style={{
            left: `${p.x}%`,
            top: `${p.y}%`,
            width: `${p.size}px`,
            height: `${p.size}px`,
            backgroundColor: p.color,
            borderRadius: '2px',
            transform: `rotate(${p.rotation}deg)`
          }}
        />
      ))}
    </div>
  );
};

const VerifyEmail = () => {
  const [status, setStatus] = useState('verifying'); // verifying, success, error
  const [message, setMessage] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const isVerifying = useRef(false);
  const tokenRef = useRef('');
  
  useEffect(() => {
    const verifyEmail = async () => {
      // Prevent multiple verification attempts
      if (isVerifying.current) return;
      isVerifying.current = true;
      
      try {
        // CHANGE: Extract token from path instead of query parameter
        // Method 1: Get from URL pathname (if URL is like /verify-email/abc123)
        const pathParts = location.pathname.split('/');
        let token = pathParts[pathParts.length - 1];
        
        // Method 2: Fallback to query parameter (for backwards compatibility with old emails)
        if (!token || token === 'verify-email') {
          token = new URLSearchParams(location.search).get('token');
        }
        
        // Store token in ref for later use
        tokenRef.current = token;
        
        if (!token) {
          setStatus('error');
          setMessage('Verificatie token ontbreekt. Controleer de URL in uw e-mail.');
          isVerifying.current = false;
          return;
        }
        
        console.log('Found token in URL:', token);
        
        // Check if this token was already verified successfully
        const verifiedTokens = JSON.parse(localStorage.getItem('verifiedTokens') || '{}');
        
        if (verifiedTokens[token]) {
          setStatus('success');
          setMessage('Uw e-mailadres is succesvol geverifieerd');
          setShowConfetti(true);
          isVerifying.current = false;
          return;
        }
        
        // Immediately save this token as "processing" to prevent duplicate attempts
        verifiedTokens[`${token}_processing`] = true;
        localStorage.setItem('verifiedTokens', JSON.stringify(verifiedTokens));
        
        // IMPORTANT: Token comes as a query parameter in the URL, but the API expects it as a path parameter
        console.log('Verifying token:', token);
        const response = await api.get(`/auth/verify-email/${token}`);
        
        if (response.data.success) {
          // Immediately update localStorage and UI to avoid race conditions
          const updatedTokens = JSON.parse(localStorage.getItem('verifiedTokens') || '{}');
          delete updatedTokens[`${token}_processing`];
          updatedTokens[token] = {
            timestamp: new Date().toISOString(),
            email: response.data.email || '',
            status: 'verified'
          };
          localStorage.setItem('verifiedTokens', JSON.stringify(updatedTokens));
          
          // Update UI with success
          setStatus('success');
          setVerifiedEmail(response.data.email || '');
          setMessage(response.data.message || 'Uw e-mailadres is succesvol geverifieerd');
          setShowConfetti(true);
        } else {
          // Remove processing flag
          const updatedTokens = JSON.parse(localStorage.getItem('verifiedTokens') || '{}');
          delete updatedTokens[`${token}_processing`];
          localStorage.setItem('verifiedTokens', JSON.stringify(updatedTokens));
          
          // Handle specific error cases based on status code
          switch (response.data.status) {
            case 'already_verified':
              // If already verified, treat as success for better UX
              updatedTokens[token] = {
                timestamp: new Date().toISOString(),
                email: response.data.email || '',
                status: 'already_verified'
              };
              localStorage.setItem('verifiedTokens', JSON.stringify(updatedTokens));
              
              setStatus('already-verified');
              setVerifiedEmail(response.data.email || '');
              setMessage(response.data.message || 'Dit e-mailadres is al geverifieerd. U kunt nu inloggen.');
              break;
            case 'expired_token':
              setStatus('expired');
              setMessage(response.data.message || 'Uw verificatie link is verlopen. U kunt een nieuwe verificatie e-mail aanvragen via het dashboard.');
              break;
            case 'invalid_token':
              // Check if this might be a previously used token that was successful
              if (Object.keys(updatedTokens).some(t => !t.includes('_processing') && t.startsWith(token.substring(0, 8)))) {
                setStatus('already-verified');
                setMessage('Dit e-mailadres lijkt al geverifieerd te zijn. U kunt nu inloggen.');
              } else {
                setStatus('error');
                setMessage(response.data.message || 'Verificatie token is ongeldig. Controleer de URL of vraag een nieuwe verificatie e-mail aan.');
              }
              break;
            default:
              setStatus('error');
              setMessage(response.data.message || 'Verificatie mislukt');
          }
        }
      } catch (error) {
        // Remove processing flag
        const token = new URLSearchParams(location.search).get('token');
        if (token) {
          const verifiedTokens = JSON.parse(localStorage.getItem('verifiedTokens') || '{}');
          delete verifiedTokens[`${token}_processing`];
          localStorage.setItem('verifiedTokens', JSON.stringify(verifiedTokens));
        }
        
        setStatus('error');
        // More specific error handling
        if (error.response?.status === 404) {
          // Check if this might be a previously used token that was successful
          const token = new URLSearchParams(location.search).get('token');
          const verifiedTokens = JSON.parse(localStorage.getItem('verifiedTokens') || '{}');
          
          if (token && Object.keys(verifiedTokens).some(t => !t.includes('_processing') && t.startsWith(token.substring(0, 8)))) {
            setStatus('already-verified');
            setMessage('Dit e-mailadres lijkt al geverifieerd te zijn. U kunt nu inloggen.');
          } else {
            setMessage('Verificatie token niet gevonden. Mogelijk is de link al gebruikt of ongeldig.');
          }
        } else {
          setMessage(error.response?.data?.message || 'Er is een fout opgetreden bij het verifiëren van uw e-mail');
        }
      } finally {
        isVerifying.current = false;
      }
    };
    
    verifyEmail();
  }, [location]);
  
  const handleRedirect = () => {
    // Pass verification status to login page
    if (status === 'already-verified' || status === 'success') {
      const email = verifiedEmail || JSON.parse(localStorage.getItem('verifiedTokens') || '{}')[tokenRef.current]?.email || '';
      navigate('/login', { 
        state: { 
          verificationStatus: status,
          verificationMessage: message,
          email: email
        } 
      });
    } else {
      navigate('/login');
    }
  };
  
  const handleDashboardRedirect = () => {
    navigate('/');
  };
  
  return (
    <div className="min-h-screen flex items-center justify-center p-4"
         style={{ backgroundColor: 'var(--color-bg-secondary)' }}>
      {showConfetti && <Confetti />}
      <div className="w-full max-w-md p-8 rounded-lg shadow-md"
           style={{ backgroundColor: 'var(--color-bg-primary)' }}>
        <div className="text-center mb-6">
          <h1 className="text-2xl font-bold mb-4" 
              style={{ color: 'var(--color-text-primary)' }}>
            E-mail Verificatie
          </h1>
          
          {status === 'verifying' && (
            <div className="flex flex-col items-center">
              <div style={{ borderColor: 'var(--color-accent)' }} 
                   className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 mb-4"></div>
              <p style={{ color: 'var(--color-text-secondary)' }}>
                Uw e-mailadres wordt geverifieerd...
              </p>
            </div>
          )}
          
          {status === 'success' && (
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full flex items-center justify-center mb-6" 
                   style={{ backgroundColor: 'rgba(34, 197, 94, 0.1)' }}>
                <CheckCircle size={40} className="text-green-500" />
              </div>
              
              <h2 className="text-xl font-bold mb-4" style={{ color: 'var(--color-text-primary)' }}>
                Verificatie Succesvol!
              </h2>
              
              <p className="text-center mb-6" style={{ color: 'var(--color-text-secondary)' }}>
                {message}
              </p>
              
              <div className="w-full p-4 mb-6 rounded-lg flex items-center space-x-3"
                   style={{ backgroundColor: 'rgba(34, 197, 94, 0.1)' }}>
                <CheckCircle size={20} className="text-green-500 flex-shrink-0" />
                <p className="text-sm" style={{ color: 'var(--color-text-secondary)' }}>
                  U kunt nu alle functies van de Inkooporders-app gebruiken!
                </p>
              </div>
              
              <button
                onClick={handleRedirect}
                className="flex items-center justify-center px-6 py-3 rounded-md text-white w-full transition-all duration-200"
                style={{ backgroundColor: 'var(--color-accent)' }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#1a4ba8';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = 'var(--color-accent)';
                }}
              >
                Ga naar inloggen
                <ArrowRight className="ml-2 w-4 h-4" />
              </button>
            </div>
          )}
          
          {status === 'already-verified' && (
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full flex items-center justify-center mb-6" 
                   style={{ backgroundColor: 'rgba(34, 197, 94, 0.1)' }}>
                <CheckCircle size={40} className="text-green-500" />
              </div>
              
              <h2 className="text-xl font-bold mb-4" style={{ color: 'var(--color-text-primary)' }}>
                E-mail is al geverifieerd
              </h2>
              
              <p className="text-center mb-6" style={{ color: 'var(--color-text-secondary)' }}>
                {message}
              </p>
              
              <button
                onClick={handleRedirect}
                className="flex items-center justify-center px-6 py-3 rounded-md text-white w-full transition-all duration-200"
                style={{ backgroundColor: 'var(--color-accent)' }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#1a4ba8';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = 'var(--color-accent)';
                }}
              >
                Ga naar inloggen
                <ArrowRight className="ml-2 w-4 h-4" />
              </button>
            </div>
          )}
          
          {status === 'expired' && (
            <div className="flex flex-col items-center">
              <AlertTriangle size={48} className="text-amber-500 mb-4" />
              <p style={{ color: 'var(--color-warning)' }} className="mb-6">
                {message}
              </p>
              <div className="flex space-x-4">
                <button
                  onClick={handleRedirect}
                  className="px-6 py-2 rounded-md text-white transition-all duration-200"
                  style={{ backgroundColor: 'var(--color-accent)' }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = '#1a4ba8';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = 'var(--color-accent)';
                  }}
                >
                  Ga naar inloggen
                </button>
                <button
                  onClick={handleDashboardRedirect}
                  className="px-6 py-2 rounded-md transition-all duration-200 border"
                  style={{ 
                    color: 'var(--color-text-primary)',
                    borderColor: 'var(--color-border)' 
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(0,0,0,0.05)';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = 'transparent';
                  }}
                >
                  Naar dashboard
                </button>
              </div>
            </div>
          )}
          
          {status === 'error' && (
            <div className="flex flex-col items-center">
              <XCircle size={48} className="text-red-500 mb-4" />
              <p style={{ color: 'var(--color-error)' }} className="mb-6">
                {message}
              </p>
              <div className="flex space-x-4">
                <button
                  onClick={handleRedirect}
                  className="px-6 py-2 rounded-md text-white transition-all duration-200"
                  style={{ backgroundColor: 'var(--color-accent)' }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = '#1a4ba8';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = 'var(--color-accent)';
                  }}
                >
                  Ga naar inloggen
                </button>
                <button
                  onClick={handleDashboardRedirect}
                  className="px-6 py-2 rounded-md transition-all duration-200 border"
                  style={{ 
                    color: 'var(--color-text-primary)',
                    borderColor: 'var(--color-border)' 
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(0,0,0,0.05)';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = 'transparent';
                  }}
                >
                  Naar dashboard
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
