// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Dashboard from './pages/Dashboard';
import Inkooporders from './pages/Inkooporders';
import Klanten from './pages/Klanten';
import NieuweOrder from './pages/NieuweOrder';
import Login from './pages/Login';
import Register from './pages/Register';
import CompanySettings from './pages/CompanySettings';
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import ErrorFallback from './components/ErrorFallback';
import { isAuthenticated } from './services/authService';
import { ThemeProvider } from './context/ThemeContext';
import VerifyEmail from './pages/VerifyEmail';
import { logError } from './services/errorService';

function App() {
  // Error boundary handler function
  const handleError = (error, info) => {
    logError(error, info);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      <ThemeProvider>
        <Router>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={!isAuthenticated() ? <Login /> : <Navigate to="/" />} />
            <Route path="/register" element={!isAuthenticated() ? <Register /> : <Navigate to="/" />} />
            
            {/* Protected routes - each wrapped in their own error boundary */}
            <Route path="/" element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
                  <Layout>
                    <Dashboard />
                  </Layout>
                </ErrorBoundary>
              </ProtectedRoute>
            } />
            
            <Route path="/inkooporders" element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
                  <Layout>
                    <Inkooporders />
                  </Layout>
                </ErrorBoundary>
              </ProtectedRoute>
            } />
            
            <Route path="/klanten" element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
                  <Layout>
                    <Klanten />
                  </Layout>
                </ErrorBoundary>
              </ProtectedRoute>
            } />
            
            <Route path="/nieuwe-order" element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
                  <Layout>
                    <NieuweOrder />
                  </Layout>
                </ErrorBoundary>
              </ProtectedRoute>
            } />

            {/* Order edit route */}
            <Route path="/inkooporders/:id/edit" element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
                  <Layout>
                    <NieuweOrder />
                  </Layout>
                </ErrorBoundary>
              </ProtectedRoute>
            } />
            
            {/* Company Settings */}
            <Route path="/bedrijfsinstellingen" element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
                  <Layout>
                    <CompanySettings />
                  </Layout>
                </ErrorBoundary>
              </ProtectedRoute>
            } />

            {/* Verify Email */}
            <Route path="/verify-email" element={
              <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
                <VerifyEmail />
              </ErrorBoundary>
            } />
            
            {/* Verify Email with path parameter */}
            <Route path="/verify-email/:token" element={
              <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
                <VerifyEmail />
              </ErrorBoundary>
            } />

            {/* Redirect any unknown routes to dashboard if authenticated, otherwise to login */}
            <Route path="*" element={
              isAuthenticated() ? <Navigate to="/" /> : <Navigate to="/login" />
            } />
          </Routes>
        </Router>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;